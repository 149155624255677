<template>
  <tr @contextmenu="showTool" v-if="!editing" @mouseover="hover = true" @mouseleave="hover = false" @dblclick="editing=true">
    <AddressRowTool :address="address" ref="addressRowTool" @address:sendMessage="(data) => this.$emit('address:sendMessage', data)"/>
    <td style="max-width:120px;">{{ address.type.name }}</td>
    <td>{{ address.address }}</td>
    <td>{{ address.description }}</td>
    <td style="max-width:30px;">{{ address.priority }}</td>
    <td class="text-right action-cell">
      <v-btn v-if="hover" color="primary" class="mr-1" dark small @click="editing = true"> <v-icon>edit</v-icon> </v-btn>
      <v-btn v-if="hover" color="error" dark small @click="deleteAddress()"> <v-icon>delete</v-icon> </v-btn>
    </td>
  </tr>
  <tr v-else>
    <td style="max-width:120px;" class="px-1">
      <v-select 
        :items="addressTypes" 
        v-model="newAddress.type" 
        item-text="name"
        item-value="id"
        return-object
        label="rodzaj"
        :error-messages="addressTypeErrors"
      >
      </v-select> 
    </td>
    <td class="px-1" style="min-width:230px;">  
      <v-text-field 
          v-model="newAddress.address" 
          label="adres"
          :error-messages="addressErrors"
      ></v-text-field>
    </td>
    <td class="px-1">      
      <v-text-field 
          v-model="newAddress.description" 
          label="opis"
      ></v-text-field></td>
      <td style="max-width:30px;" class="px-1">
      <v-text-field 
          v-model="newAddress.priority" 
          label="P"
      ></v-text-field></td>
    <td class="text-right action-cell px-1" >
      <v-btn color="success" class="mr-1" dark small @click="save()"> <v-icon>done</v-icon> </v-btn>
       <v-btn 
          color="warning" 
          @click="cancel()" 
          small
        >
          <v-icon>not_interested</v-icon>
        </v-btn>
    </td>
  </tr>
</template>


<script>
import AddressRowTool from "./Address/AddressRowTool.vue";
import { required,email, helpers  } from "vuelidate/lib/validators";
const url = helpers.regex("url", /^[^.:]+\..+$/);

export default {
  name: "FacilityEditAddressessRow",
  props: ["address","addressTypes"],
  components: {AddressRowTool},
  data: () => ({
    editing: false,
    hover: false,
    newAddress: {
      type: {}
    }
  }),
  validations() {
    if(([7, 8, 9].includes(this.newAddress.type.id ))){
      return {
        newAddress: {
         address: {required,email},
         type:{required}
        }
      };   
    } else if(([10].includes(this.newAddress.type.id ))){
      return {
        newAddress: {
         address: {required},
         type:{required}
        }
      };  
    } else {
      return {
        newAddress: {
         address: {required, url},
         type:{required}
        }
      };  
    }
  },
  computed: {
    addressErrors(){
      const errors = [];
      if (!this.$v.newAddress.address.$dirty) return errors;
      !this.$v.newAddress.address.required && errors.push("podaj adres")
      this.$v.newAddress.address.email != null && !this.$v.newAddress.address.email && errors.push("podaj email")
      this.$v.newAddress.address.url != null && !this.$v.newAddress.address.url && errors.push("podaj url")
      ;
      return errors;
    },
    addressTypeErrors(){
      const errors = [];
      if (!this.$v.newAddress.type.$dirty) return errors;
      !this.$v.newAddress.type.required && errors.push("rodzaj jest wymagany")
      ;
      return errors;
    },
  },
  methods: {
    save: function(){
     this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.editing = false;
      this.$emit('address:update', this.newAddress)
    },
    cancel: function(){
      this.newAddress= JSON.parse(JSON.stringify(this.address));
      this.editing = false;
    },
    deleteAddress: function(){
      this.$emit('address:remove', this.address) 
    },
    showTool(e){
      this.$refs.addressRowTool.show(e);
    }
  },
  mounted() {
     this.newAddress= JSON.parse(JSON.stringify(this.address));
  },
};
</script>


<style>
.action-cell {
  min-width:130px;
}
</style>