var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{attrs:{"label":"nie w mailingu","value":1,"dense":""},model:{value:(_vm.facility.facility.isMailingDisabled),callback:function ($$v) {_vm.$set(_vm.facility.facility, "isMailingDisabled", $$v)},expression:"facility.facility.isMailingDisabled"}})],1)]}}])},[_c('span',[_vm._v("nie wysyłaj mailingu do obiektu")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{attrs:{"label":"nie sms faktura","value":1,"dense":""},model:{value:(_vm.facility.disable_invoice_sms_notifications),callback:function ($$v) {_vm.$set(_vm.facility, "disable_invoice_sms_notifications", $$v)},expression:"facility.disable_invoice_sms_notifications"}})],1)]}}])},[_c('span',[_vm._v("nie wysyłaj powiadomienia o wystawienia faktury sms-em")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{attrs:{"label":"cały adres www","value":1,"dense":""},model:{value:(_vm.facility.facility.showWwwHost),callback:function ($$v) {_vm.$set(_vm.facility.facility, "showWwwHost", $$v)},expression:"facility.facility.showWwwHost"}})],1)]}}])},[_c('span',[_vm._v("pokaż pełen adres prywatnej strony internetowej w serwisach")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{attrs:{"label":"nie info","value":1,"dense":""},model:{value:(_vm.facility.facility.showGoodPricesInfo),callback:function ($$v) {_vm.$set(_vm.facility.facility, "showGoodPricesInfo", $$v)},expression:"facility.facility.showGoodPricesInfo"}})],1)]}}])},[_c('span',[_vm._v("ukryj informacje 'powołaj się na informacje FIRMY METEOR ...'")])])],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"pa-2 font-weight-bold"},[_vm._v("telefony")]),_c('v-divider'),_c('FacilityEditPhones',{attrs:{"facility":_vm.facility},on:{"phone:update":function($event){return _vm.$emit('autosave')}}})],1),_c('v-card',{staticClass:"mt-5 ",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"pa-2 font-weight-bold"},[_vm._v("adresy www")]),_c('v-divider'),_c('FacilityEditAddresses',{attrs:{"facility":_vm.facility,"availableTypes":[1,2,3,4,5,6],"validateTypeDuplication":false},on:{"address:update":function($event){return _vm.$emit('autosave')}}})],1),_c('v-card',{staticClass:"mt-5 ",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"pa-2 font-weight-bold"},[_vm._v("adresy email")]),_c('v-divider'),_c('FacilityEditAddresses',{attrs:{"facility":_vm.facility,"availableTypes":[7,8,9,10,11,12,13],"validateTypeDuplication":true},on:{"address:update":function($event){return _vm.$emit('autosave')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }